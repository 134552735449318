<template>
    <div class="rounded-bottom sub-menu-container">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'SubMenuContainer',
}
</script>

<style scoped>
    .sub-menu-container {
        background-color: white;
    }
</style>